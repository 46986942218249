<template>
    <div class="body-width">
        <van-form @submit="onSubmit">
          <van-field class="login"
            v-model="form.username"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field class="login"
            v-model="form.password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">
              提交
            </van-button>
          </div>
        </van-form>
    </div>
</template>

<script>
import {login} from "@/api/user";
import {ticket, shareTitle, shareUrl, shareImg, shareDesc, commonShare} from "@/api/wxshare";
export default {
    name: "login",
    data() {
      return {
        form: {
          username: '',
          password: ''
        }
      }
    },
    created() {
        let _this = this;
        _this.updateShare();
    },
    methods: {
        async updateShare() {
            let _this = this;
            const res = await ticket({url:window.location.href});
            if (res.code == 1) {
                commonShare(res.data, shareTitle, window.location.href, shareImg, shareDesc)
            }
        },
        async onSubmit() {
            this.$toast.loading({
              message: '登录中...',
              forbidClick: true,
            });
            let _this = this;
            const res = await login({account:_this.form.username,password:_this.form.password});
            if (res.code == 1) {
                localStorage.setItem('token', res.data.token);
                this.$toast.setDefaultOptions({ duration: 1000 });
                this.$toast.success(res.msg);
                setTimeout(ress=> {
                    this.$router.push({
                        path:'/'
                    })
                }, 1500); 
            }
        }
    }
}
</script>
